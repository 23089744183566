<template>
    <div class="wegweiser" :class="{visible}">
        <div class="pfosten">
            <slot name="pfosten"/>
        </div>
        <div class="schilder">
            <slot/>
        </div>
        <slot name="sonstiges"/>
    </div>
</template>

<script>
export default {
    props: {
        schildHoehe: {
            type: Number,
            default: 10
        },
        visible: Boolean,
    },
    mounted() {
        this.setToStartPositions()
    },
    watch: {
        visible(n) {
            if (n) setTimeout(this.setToKeyHeight.bind(this), 1000)
            else this.setToStartPositions()
        }
    },
    methods: {
        setToStartPositions() {
            const schilder = this.$el.querySelector('.schilder').children
            let h = this.schildHoehe
            for (let i = 0; i < schilder.length; i++) {
                const schild = schilder[i]
                schild.style.top = h +'px'
                const r = schild.getBoundingClientRect()
                h += r.height + this.schildHoehe
            }
        },

        setToKeyHeight() {
            const schilder = this.$el.querySelector('.schilder').children
            let h = this.schildHoehe
            for (let i = 0; i < schilder.length; i++) {
                const schild = schilder[i]
                schild.style.top = h +'px'
                const r = schild.getBoundingClientRect()
                h += r.height
            }
        },

        enter() {
            setTimeout(this.setToKeyHeight.bind(this), 600)
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../styles/colors' as *;

.wegweiser {
	position: relative;

    $d: .3s;
    $delay: .7s;

	.pfosten {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);

        $x: 20px;
		width: $x;
		background-color: #45281f;
		border-top-right-radius: $x / 2;
		border-top-left-radius: $x / 2;

        transition: background-color $d $delay;

		&::before {
			content: "";
			width: $x * 3;
			height: $x * 3;
			border-radius: 50%;
			border: 20px solid grey;
			position: absolute;
			top: calc(100% - (#{$x} / 2));
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			transition:
                opacity $d $delay;
		}
	}

	.schilder > * {
        $h: 2em;

        position: absolute;
        left: 50%;
        background-color: $blau;
        color: #fff;
        padding: 0 0.7em;
        height: $h;
        display: flex;
        align-items: center;
        transform-origin: left;
        transition:
            transform $d linear $delay,
            top $d linear $delay;

        > * {
            transition: transform $d linear $delay;
        }

		&::after {
			content: "";
			border: ($h / 2) solid transparent;
			border-left-color: $blau;
			left: calc(100% - 1px);
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
		}
	}

    &.visible {
		.schilder > * {
            transform: translate(0) rotate(0) !important;
            > * {
                transform: rotate(0) !important;
            }
		}

        .pfosten {
            background-color: grey;
        }

		.pfosten::before {
            opacity: 1;
		}
	}
}
</style>